import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFilter } from "../contexts/FilterContext.jsx";
import { SORT_OPTIONS, SORT_ORDER } from "../utils/constants.js";
import Loading from "../components/common/Loading.jsx";
import LoadingError from "../components/common/LoadingError.jsx";
import SEO from "../components/common/SEO.jsx";
import { useCategories, useOffers, useStores } from "../hooks/useFetch.js";
import { useProducts } from "../hooks/useProducts.js";
import HomeSlider from "../components/home/HomeSlider.jsx";
import ProductsSample from "../components/home/ProductsSample.jsx";
import MainCategories from "../components/home/MainCategories.jsx";
import MainStores from "../components/home/MainStores.jsx";
import { getLocalizedData } from "../utils/getLocalizedData.js";
// import AppBanner from "../components/home/AppBanner.jsx";
// import useIsMobile from "../hooks/useIsMobile.jsx";

const HomePage = () => {
    // for translation
    const { t, i18n } = useTranslation();
    // Get the current language
    const language = i18n.resolvedLanguage;

    // State and dispatch from context
    const { dispatch } = useFilter();
    const navigate = useNavigate();

    // Check if the device is mobile
    // const isMobile = useIsMobile();

    // Fetch data using custom hooks
    const {
        data: categories,
        isLoading: categoriesLoading,
        error: categoriesError,
    } = useCategories();
    const {
        data: stores,
        isLoading: storesLoading,
        error: storesError,
    } = useStores();
    const {
        data: offers,
        isLoading: offersLoading,
        error: offersError,
    } = useOffers();

    const productsLimit = 4;

    // to get productsList data for deals and offers section and recently added section
    const dealsFilters = useMemo(
        () => ({
            selectedMainCategories: [0],
            page: 1,
            sortBy: SORT_OPTIONS.DEALS,
            order: SORT_ORDER.DESC,
            lang: language,
        }),
        [language],
    );

    const recentlyFilters = useMemo(
        () => ({
            selectedMainCategories: [0],
            page: 1,
            sortBy: SORT_OPTIONS.RECENTLY,
            order: SORT_ORDER.DESC,
            lang: language,
        }),
        [language],
    );

    const {
        data: deals,
        isLoading: dealsLoading,
        error: dealsError,
    } = useProducts(dealsFilters, productsLimit);
    const {
        data: recently,
        isLoading: recentlyLoading,
        error: recentlyError,
    } = useProducts(recentlyFilters, productsLimit);

    const handleSeeMoreClick = useCallback(
        (sortBy, order) => {
            dispatch({ type: "SET_SORT_BY", payload: sortBy });
            dispatch({ type: "SET_ORDER", payload: order });
            navigate("/product");
        },
        [dispatch, navigate],
    );

    const handleStoreClick = useCallback(
        (storeId) => {
            dispatch({ type: "SET_SELECTED_STORES", payload: [storeId] });
            navigate("/product");
        },
        [dispatch, navigate],
    );

    // Check loading and error states
    const isLoading =
        categoriesLoading ||
        storesLoading ||
        offersLoading ||
        dealsLoading ||
        recentlyLoading;
    const errors =
        categoriesError ||
        storesError ||
        offersError ||
        dealsError ||
        recentlyError;

    // Get localized data
    const localizedCategories = useMemo(
        () => getLocalizedData(categories, language),
        [categories, language],
    );
    const localizedStores = useMemo(
        () => getLocalizedData(stores, language),
        [stores, language],
    );
    const localizedOffers = useMemo(
        () => getLocalizedData(offers, language),
        [offers, language],
    );
    const dealsProducts = useMemo(
        () => getLocalizedData(deals?.products, language),
        [deals, language],
    );
    const recentlyProducts = useMemo(
        () => getLocalizedData(recently?.products, language),
        [recently, language],
    );

    // Flatten all SubcategoriesLevel2 with images into a single array
    const subcategories = useMemo(
        () =>
            localizedCategories?.flatMap((category) =>
                category.subcategories_level1.flatMap((subcategory1) =>
                    subcategory1.subcategories_level2.filter(
                        (subcategory2) => subcategory2.visible === 2,
                    ),
                ),
            ),
        [localizedCategories],
    );

    // order the stores by name
    localizedStores?.sort((a, b) => a.name.localeCompare(b.name));

    // SEO metadata
    const pageSEO = useMemo(
        () => ({
            title: t("home_page.title"),
            description: t("home_page.description"),
        }),
        [t],
    );

    if (isLoading) return <Loading />;
    if (errors) return <LoadingError errorMessage={errors.message} />;

    const handleStoreSeeMoreClick = () => {
        navigate("/stores");
    };

    return (
        <div className="font-heading mb-8">
            <SEO title={pageSEO.title} description={pageSEO.description} />

            <HomeSlider offers={localizedOffers} language={language} />

            {/* hot deals */}
            <ProductsSample
                products={dealsProducts}
                title={t("hot_deals")}
                onSeeMoreClick={() =>
                    handleSeeMoreClick(SORT_OPTIONS.DEALS, SORT_ORDER.DESC)
                }
                currentLanguage={language}
            />

            {/* Main Categories */}
            <MainCategories subcategories={subcategories} />

            {/* recently added */}
            <ProductsSample
                products={recentlyProducts}
                title={t("recently_added")}
                onSeeMoreClick={() =>
                    handleSeeMoreClick(SORT_OPTIONS.RECENTLY, SORT_ORDER.DESC)
                }
                currentLanguage={language}
            />

            {/* Main Stores */}
            <MainStores
                stores={localizedStores.slice(0, 4)}
                onStoreClick={handleStoreClick}
                onSeeMoreClick={() => handleStoreSeeMoreClick()}
            />

            {/* {isMobile && <AppBanner />} */}
        </div>
    );
};

export default HomePage;
